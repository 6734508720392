<template>
  <div class="stipendiatStories">
    <button class="stipendiatStories__btn">Добавить</button>
    <div class="stipendiatStories__container">
      <p class="stipendiatStories__header">Перечень историй успеха</p>
      <!--      v-for историй-->
      <div class="stipendiatStory">
        <img src="@/assets/icons/empty-photo.svg" class="stipendiatStory__photo" alt="Фото">
        <div class="stipendiatStory__info-container">
          <p class="stipendiatStory__info-name">
            Алиуллина Лейсан Айратовна
          </p>
          <p class="stipendiatStory__info-grant">
            Стипендия Правительства Российской Федерации по приоритетным направлениям
          </p>
          <p class="stipendiatStory__info-year">
            2020
          </p>
        </div>
        <img src="@/assets/icons/video.svg" class="stipendiatStory__video" alt="Видео">
        <button class="stipendiatStories__btn-edit">Изменить</button>
      </div>
      <div class="stipendiatStory">
        <img src="@/assets/icons/empty-photo.svg" class="stipendiatStory__photo" alt="Фото">
        <div class="stipendiatStory__info-container">
          <p class="stipendiatStory__info-name">
            Алиуллина Лейсан Айратовна
          </p>
          <p class="stipendiatStory__info-grant">
            Стипендия Правительства Российской Федерации по приоритетным направлениям
          </p>
          <p class="stipendiatStory__info-year">
            2020
          </p>
        </div>
        <img src="@/assets/icons/no-video.svg" class="stipendiatStory__video" alt="Видео">
        <button class="stipendiatStories__btn-edit">Изменить</button>
      </div>
      <div class="stipendiatStory">
        <img src="@/assets/icons/empty-photo.svg" class="stipendiatStory__photo" alt="Фото">
        <div class="stipendiatStory__info-container">
          <p class="stipendiatStory__info-name">
            Алиуллина Лейсан Айратовна
          </p>
          <p class="stipendiatStory__info-grant">
            Стипендия Правительства Российской Федерации по приоритетным направлениям
          </p>
          <p class="stipendiatStory__info-year">
            2020
          </p>
        </div>
        <img src="@/assets/icons/video.svg" class="stipendiatStory__video" alt="Видео">
        <button class="stipendiatStories__btn-edit" @click="changeStory">Изменить</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StipendiatSborStories",
  props: {
    edit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {

    }
  },
  methods: {
    changeStory() {
      this.$emit('edit-story', true);
    }
  }
}
</script>

<style scoped>

</style>