<template>
  <StipendiatSborStories
      v-if="!edit"
      :edit="edit"
      @edit-story="componentSwitch"
  />
  <StipendiatSborStory
      v-else
      :edit="edit"
      @edit-story="componentSwitch"
  />
</template>

<script>
import StipendiatSborStories from "@/layouts/stipendiatSborStories/StipendiatSborStories";
import StipendiatSborStory from "@/layouts/stipendiatSborStories/StipendiatSborStory";

export default {
  name: "StipendiatSborStoriesView",
  components: {StipendiatSborStory, StipendiatSborStories},
  data() {
    return {
      edit: false
    }
  },
  // props: {
  //   edit: {
  //     type: Boolean,
  //     default: false
  //   },
  // },
  methods: {
    componentSwitch(e) {
      this.edit = e;
    }
  }
}
</script>

<style scoped>

</style>