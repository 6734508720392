<template>
  <div class="stipendiatStories">
    <button class="stipendiatStories__btn-all" @click="changeStory">Все истории успеха</button>
    <div class="stipendiatStories__container">
      <p class="stipendiatStories__header">Сведения об истории успеха</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StipendiatSborStory",
  methods: {
    changeStory() {
      this.$emit('edit-story', false);
    }
  }
}
</script>

<style scoped>

</style>